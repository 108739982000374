import {
  ActivateAccountRequest,
  CreateAccountSpec,
  LoginSpec,
  ResetPasswordSpec,
  SubmitEmailChangeRequest,
  ValidateLoginSpec,
  ValidatePasswordResetSpec,
} from '@oproma/prividox-orchestration-open-api';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { authenticationService, extractApiError, userService } from '../api';
import { AppState } from '../store';
import { getUserMetadata, setValidMFACode } from '../user';
import {
  AuthErrorCodesEnum,
  AuthenticationStatusEnum,
  IAuthState,
} from './types';

const initialState: IAuthState = {
  uid: null,
  error: null,
  loading: false,
  isLocked: false,
  resetEmail: false,
  requiresMFA: false,
  expiredAuth: false,
  disabledMFA: false,
  passwordReset: false,
  financeWebtoken: null,
  emailConfirmed: false,
  isAuthenticated: false,
  displayPasswordText: false,
  completedOnboarding: false,
  resetEmailDispatched: false,
  completedPasswordReset: false,
  receivedEmailResetDetails: false,
  completedEmailConfirmation: false,
  registrationEmailDispatched: false,
  resetPasswordEmailDispatched: false,
  receivedPasswordResetConfirmationDetails: false,
};

export const login = createAsyncThunk(
  '@@auth/login',
  async (loginPayload: LoginSpec, { dispatch, rejectWithValue }) => {
    try {
      const user = await authenticationService.loginUser(loginPayload);
      const financePayload: ValidateLoginSpec = {
        email: loginPayload.email,
        pass: loginPayload.pass,
      };
      if (loginPayload.token) {
        financePayload.token = loginPayload.token;
      }
      const financeWebtoken =
        await authenticationService.reauthenticateForFinances(financePayload);
      return { user, financeWebtoken };
    } catch (e) {
      console.error(e);
      const extractedError = extractApiError(e as Error);
      if (extractedError === 'TWOFA::challenge') {
        dispatch(setRequiresMFA(true));
      }
      return rejectWithValue(extractedError);
    }
  },
);

export const activateUser = createAsyncThunk(
  '@@auth/activateUser',
  async (
    activateAccountPayload: ActivateAccountRequest,
    { rejectWithValue },
  ) => {
    try {
      const activated = await authenticationService.activateUser(
        activateAccountPayload,
      );
      if (!activated) {
        return rejectWithValue('Failed to activate user. Please try again.');
      }
      toast.success('User account activation completed.');
    } catch (e) {
      console.error(e);
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const logout = createAsyncThunk(
  '@@auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      const logoutStatus = await authenticationService.logoutUser();
      if (!logoutStatus) {
        return rejectWithValue('Failed to logout user. Please try again.');
      }

      return logoutStatus;
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to logout user. Please try again.');
    }
  },
);

export const register = createAsyncThunk(
  '@@auth/register',
  async (registerPayload: CreateAccountSpec, { rejectWithValue }) => {
    try {
      const registerStatus = await authenticationService.registerUser(
        registerPayload,
      );
      return !!registerStatus;
    } catch (e) {
      console.error(e);
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const registerConfirmation = createAsyncThunk(
  '@@auth/registerConfirmation',
  async (
    confirmRegistrationPayload: ActivateAccountRequest,
    { rejectWithValue },
  ) => {
    try {
      const confirmRegistrationStatus =
        await authenticationService.confirmRegistration(
          confirmRegistrationPayload,
        );

      return (
        confirmRegistrationStatus.status === AuthenticationStatusEnum.COMPLETED
      );
    } catch (e) {
      console.error(e);
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const resetPassword = createAsyncThunk(
  '@@auth/resetPassword',
  async (resetPasswordPayload: ResetPasswordSpec, { rejectWithValue }) => {
    try {
      const resetPasswordStatus = await authenticationService.resetPassword(
        resetPasswordPayload,
      );
      toast.success('Password reset email dispatched.');
      return resetPasswordStatus.status === AuthenticationStatusEnum.COMPLETED;
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to reset password. Please try again.');
    }
  },
);

export const resetPasswordConfirmation = createAsyncThunk(
  '@@auth/resetPasswordConfirmation',
  async (
    resetPasswordConfirmationPayload: ValidatePasswordResetSpec,
    { rejectWithValue },
  ) => {
    try {
      const resetPasswordConfirmationStatus =
        await authenticationService.confirmResetPassword(
          resetPasswordConfirmationPayload,
        );
      return (
        resetPasswordConfirmationStatus.status ===
        AuthenticationStatusEnum.COMPLETED
      );
    } catch (e) {
      console.error(e);
      let parsedErrorMessage =
        'Failed to confirm reset password. Please try again.';

      if (e instanceof Error && e.message) {
        try {
          const { error } = JSON.parse(e.message);
          if (error) {
            parsedErrorMessage = error;
          }
        } catch (parseError) {
          console.error('Error parsing message:', parseError);
        }
      }

      return rejectWithValue(parsedErrorMessage);
    }
  },
);

export const setCompletedOnboardingStatus = createAsyncThunk(
  '@@auth/setCompletedOnboardingStatus',
  async (_, { rejectWithValue }) => {
    try {
      const loginCount = await userService.fetchLoginCount();
      return loginCount > 1;
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to fetch login count. Please try again.');
    }
  },
);

export const refreshAuth = createAsyncThunk(
  '@@auth/refreshAuth',
  async (_, { rejectWithValue }) => {
    try {
      const response = await authenticationService.refreshAuth();
      return response;
    } catch (e) {
      console.error(e);
      return rejectWithValue(
        'Failed to refresh authentication. Please try again.',
      );
    }
  },
);

export const requestEmailChange = createAsyncThunk(
  '@@auth/requestEmailChange',
  async (_, { rejectWithValue }) => {
    try {
      await authenticationService.requestEmailChange();
    } catch (e) {
      console.error(e);
      return rejectWithValue(
        'Failed to dispatch reset email link. Please try again.',
      );
    }
  },
);

export const confirmEmailChange = createAsyncThunk(
  '@@auth/confirmEmailChange',
  async (payload: SubmitEmailChangeRequest, { rejectWithValue }) => {
    try {
      await authenticationService.confirmEmailChange(payload);
    } catch (e) {
      console.error(e);
      return rejectWithValue(
        'Failed to confirm email reset. Please try again.',
      );
    }
  },
);

export const disableMFA = createAsyncThunk(
  '@@auth/disableMFA',
  async (payload: string, { getState, dispatch, rejectWithValue }) => {
    try {
      const disabledStatus = await authenticationService.disableMFA(payload);

      const uid = (getState() as AppState).auth.uid;
      dispatch(getUserMetadata(uid));

      dispatch(setValidMFACode(null));

      // TODO: This should be translated
      toast.success('Disabled MFA');
      return disabledStatus;
    } catch (e) {
      console.error(e);
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const authSlice = createSlice({
  name: '@@auth',
  initialState,
  reducers: {
    resetAuthState: (state) => {
      state.isAuthenticated = false;
      state.isLocked = false;
      state.uid = null;
      state.resetPasswordEmailDispatched = false;
      state.passwordReset = false;
      state.registrationEmailDispatched = false;
      state.emailConfirmed = false;
      state.loading = false;
      state.error = null;
    },
    setCompletedOnboarding: (state, action: PayloadAction<boolean>) => {
      state.completedOnboarding = action.payload;
    },
    setCompletedResetPassword: (state, action: PayloadAction<boolean>) => {
      state.completedPasswordReset = action.payload;
    },
    setCompletedEmailConfirmation: (state, action: PayloadAction<boolean>) => {
      state.completedEmailConfirmation = action.payload;
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setIsLocked: (state, action: PayloadAction<boolean>) => {
      state.isLocked = action.payload;
    },
    setExpiredAuth: (state, action: PayloadAction<boolean>) => {
      state.expiredAuth = action.payload;
    },
    setResetPasswordEmailDispatched: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.resetPasswordEmailDispatched = action.payload;
    },
    setRegistrationEmailDispatched: (state, action: PayloadAction<boolean>) => {
      state.registrationEmailDispatched = action.payload;
    },
    setReceivedEmailResetDetails: (state, action: PayloadAction<boolean>) => {
      state.receivedEmailResetDetails = action.payload;
    },
    setReceivedPasswordResetConfirmationDetails: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.receivedPasswordResetConfirmationDetails = action.payload;
    },
    toggleDisplayPasswordText: (state) => {
      state.displayPasswordText = !state.displayPasswordText;
    },
    setRequiresMFA: (state, action: PayloadAction<boolean>) => {
      state.requiresMFA = action.payload;
    },
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: AuthErrorCodesEnum.LOGIN_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(login.fulfilled, (state, action) => {
        const { user, financeWebtoken } = action.payload;
        state.loading = false;
        state.isAuthenticated = true;
        state.uid = user.uid;
        state.financeWebtoken = financeWebtoken;
        state.expiredAuth = false;
        state.error = null;
        state.isLocked = false;
      });
    builder
      .addCase(logout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: AuthErrorCodesEnum.LOGOUT_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.expiredAuth = false;
        state.uid = null;
        state.completedOnboarding = false;
        state.resetPasswordEmailDispatched = false;
        state.passwordReset = false;
        state.registrationEmailDispatched = false;
        state.emailConfirmed = false;
        state.error = null;
      });
    builder
      .addCase(register.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: AuthErrorCodesEnum.REGISTRATION_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.registrationEmailDispatched = action.payload as boolean;
        state.error = null;
      });
    builder
      .addCase(registerConfirmation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerConfirmation.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: AuthErrorCodesEnum.REGISTRATION_CONFIRMATION_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(registerConfirmation.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.emailConfirmed = true;
          state.error = null;
        } else {
          state.error = {
            code: AuthErrorCodesEnum.REGISTRATION_CONFIRMATION_FAILED,
            message: 'Failed to confirm registration. Please try again.',
          };
        }
      });
    builder
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: AuthErrorCodesEnum.RESET_PASSWORD_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.resetPasswordEmailDispatched = action.payload as boolean;
        state.error = null;
      });
    builder
      .addCase(resetPasswordConfirmation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPasswordConfirmation.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: AuthErrorCodesEnum.RESET_PASSWORD_CONFIRMATION_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(resetPasswordConfirmation.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.passwordReset = true;
          state.error = null;
        } else {
          state.error = {
            code: AuthErrorCodesEnum.RESET_PASSWORD_CONFIRMATION_FAILED,
            message: 'Failed to confirm reset password. Please try again.',
          };
        }
      });
    builder
      .addCase(setCompletedOnboardingStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(setCompletedOnboardingStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: AuthErrorCodesEnum.GET_COMPLETED_ONBOARDING_STATUS_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(setCompletedOnboardingStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.completedOnboarding = action.payload as boolean;
        state.error = null;
      });
    builder
      .addCase(refreshAuth.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(refreshAuth.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: AuthErrorCodesEnum.REFRESH_USER_SESSION_STATUS_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(refreshAuth.fulfilled, (state, action) => {
        state.loading = false;
        state.expiredAuth = action.payload;
      });
    builder
      .addCase(requestEmailChange.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.resetEmail = false;
      })
      .addCase(requestEmailChange.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: AuthErrorCodesEnum.RESET_EMAIL_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(requestEmailChange.fulfilled, (state) => {
        state.loading = false;
        state.resetEmail = true;
        state.error = null;
      });
    builder
      .addCase(confirmEmailChange.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.resetEmailDispatched = false;
      })
      .addCase(confirmEmailChange.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: AuthErrorCodesEnum.RESET_EMAIL_CONFIRMATION_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(confirmEmailChange.fulfilled, (state, action) => {
        state.loading = false;
        state.resetEmailDispatched = true;
        state.error = null;
      });
    builder
      .addCase(disableMFA.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.resetEmailDispatched = false;
      })
      .addCase(disableMFA.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: AuthErrorCodesEnum.DISABLE_MFA_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(disableMFA.fulfilled, (state, action) => {
        state.loading = false;
        state.disabledMFA = action.payload;
        state.error = null;
      });
  },
});

export const {
  setIsLocked,
  resetAuthState,
  setRequiresMFA,
  setExpiredAuth,
  setIsAuthenticated,
  setCompletedOnboarding,
  toggleDisplayPasswordText,
  setCompletedResetPassword,
  setReceivedEmailResetDetails,
  setCompletedEmailConfirmation,
  setRegistrationEmailDispatched,
  setResetPasswordEmailDispatched,
  setReceivedPasswordResetConfirmationDetails,
  resetError,
} = authSlice.actions;

export const authReducer = authSlice.reducer;
