import {
  ConvertDocumentRequest,
  CopyToRequest,
  DeleteMetadataRequest,
  SetProtectionLevelRequest,
  UploadStatus,
} from '@oproma/prividox-orchestration-open-api';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import {
  extractApiError,
  filesService,
  metadataService,
  workspacesService,
} from '../api';
import { AppState } from '../store';
import {
  CopyToRequestWithId,
  CreateFilePayload,
  CreateFolderWithTypeRequest,
  EditEntityMetadataPayload,
  Entity,
  EntityMetadata,
  FileWithPath,
  FilesErrorCodesEnum,
  FolderTypeEnum,
  GetEntitiesPayload,
  IFileManagerState,
} from './types';

const initialState: IFileManagerState = {
  plan: '',
  error: null,
  entities: [],
  loading: false,
  fileUploading: {},
  entityQuery: '',
  queuedFiles: [],
  contentHeight: 0,
  entityParents: [],
  trashEntities: [],
  protected: 'None',
  entityParent: null,
  displayType: 'grid',
  bulkPaste: false,
  bulkAction: false,
  previewEntities: [],
  convertedDocument: '',
  selectedEntityIds: [],
  entityParentsForMove: [],
  lastOpenedEntity: null,
  changedEntityName: false,
  lastSelectedEntityId: null,
  entityCreationProgress: {},
  displayMobileNavbar: false,
  displayRemoveEntityModal: false,
  displayMobileFinder: false,
  displayMetadataModal: false,
  changedSecurityLevel: false,
  displayQuickNavigation: true,
  displayCreateFileModal: false,
  displayMoveEntityModal: false,
  displayConvertConfirmModal: false,
  displayBulkDistributeModal: false,
  displayPreviewFileModal: false,
  displayRenameEntityModal: false,
  displayCreateFolderModal: false,
  displayMobileRecoveryFilter: false,
  displayDistributeEntityModal: false,
  displayBulkDeleteEntityModal: false,
};
const folderTypes = ['folder', 'calendar', 'gallery'];

export const getEntitiesMetadata = createAsyncThunk(
  '@@fileManager/getEntitiesMetadata',
  async (entities: string[], { rejectWithValue }) => {
    try {
      return await Promise.all(
        entities.map((entity) =>
          metadataService.fetchMetadata<EntityMetadata>(entity),
        ),
      );
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to fetch entities metadata.');
    }
  },
);

export const getEntityParents = createAsyncThunk(
  '@@fileManager/getEntityParents',
  async (entityId: string, { rejectWithValue }) => {
    try {
      const response = await filesService.fetchEntityParents(entityId);
      return response.items ?? [];
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to fetch entity parents.');
    }
  },
);

export const convertDocument = createAsyncThunk(
  '@@fileManager/convertDocument',
  async (payload: ConvertDocumentRequest, { dispatch, rejectWithValue }) => {
    try {
      const response = await filesService.convertDocument(payload);
      const entityParentId = await filesService.fetchEntityParent(
        payload.entity,
      );
      dispatch(
        getChildEntities({
          entityId: entityParentId,
        }),
      );
      return response;
    } catch (e) {
      console.error(e);
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const getEntityParentsForMove = createAsyncThunk(
  '@@fileManager/getEntityParentsForMove',
  async (entityId: string, { rejectWithValue }) => {
    try {
      const response = await filesService.fetchEntityParents(entityId);
      return response.items ?? [];
    } catch (e) {
      console.error(e);
      return rejectWithValue(
        'Failed to fetch entity parents for the move modal.',
      );
    }
  },
);

export const getEntityParent = createAsyncThunk(
  '@@fileManager/getEntityParent',
  async (entityId: string, { rejectWithValue }) => {
    try {
      const response = await filesService.fetchEntityParent(entityId);
      return response;
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to fetch entity parent.');
    }
  },
);

export const getChildEntities = createAsyncThunk(
  '@@fileManager/getChildEntities',
  async (
    { entityId, previewEntities }: GetEntitiesPayload,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const childEntities = await filesService.fetchChildEntities(entityId);

      if (!childEntities.items)
        return rejectWithValue(
          `No child entities found for root entity ${entityId}`,
        );

      const metadataResult = await dispatch(
        getEntitiesMetadata(
          childEntities.items.map((item) =>
            folderTypes.includes(item.type as string)
              ? item.folderId!
              : item.fileId!,
          ),
        ),
      );

      // Check if the metadata fetch was successful
      if (getEntitiesMetadata.fulfilled.match(metadataResult)) {
        const metadataArray = metadataResult.payload;

        // Update entities
        const response = childEntities.items.map((item) => {
          const entityId = folderTypes.includes(item.type as string)
            ? item.folderId
            : item.fileId;
          const metadata = metadataArray.find((md) => md.id === entityId);

          return {
            ...item,
            id: entityId,
            svg:
              item.type === 'folder'
                ? 'folder'
                : item.type === 'calendar'
                ? 'calendar'
                : item.type === 'gallery'
                ? 'gallery'
                : item.contentType,
            metadata: {
              starred: metadata?.starred,
            },
          };
        }) as Entity[];

        return { response, previewEntities };
      } else {
        console.error('Metadata fetching was not successful.');
        return rejectWithValue('Failed to fetch entities metadata.');
      }
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to fetch child entities.');
    }
  },
);

export const getWorkspaceEntities = createAsyncThunk(
  '@@fileManager/getWorkspaceEntities',
  async (workspaceId: string, { dispatch, rejectWithValue }) => {
    try {
      const workspaceRootFolderId = await workspacesService.fetchWorkspaceRoot(
        workspaceId,
      );

      if (!workspaceRootFolderId) {
        return rejectWithValue(
          `Couldn't find root entity for workspace ${workspaceId}`,
        );
      }

      const childEntities = await filesService.fetchChildEntities(
        workspaceRootFolderId,
      );

      if (!childEntities.items)
        return rejectWithValue(
          `No child entities found for root entity ${workspaceRootFolderId}`,
        );

      dispatch(
        getEntitiesMetadata(
          childEntities.items.map((item) =>
            folderTypes.includes(item.type as string)
              ? item.folderId!
              : item.fileId!,
          ),
        ),
      );

      return childEntities.items.map((item) => ({
        ...item,
        id: folderTypes.includes(item.type as string)
          ? item.folderId
          : item.fileId,
        svg:
          item.type === 'folder'
            ? 'folder'
            : item.type === 'calendar'
            ? 'calendar'
            : item.type === 'gallery'
            ? 'gallery'
            : item.contentType,
        metadata: {
          starred: 'false',
        },
      })) as Entity[];
    } catch (e) {
      console.error(e);
      return rejectWithValue(
        'Failed to fetch files and folders for workspace.',
      );
    }
  },
);

const chunkSize = 4 * 1024 * 1024; // 4 MiB
const maxRetries = 3;
const retryDelay = 1000; // 1 second

const normalizeUploadStatus = (data: any): UploadStatus => {
  if (typeof data !== 'object' || data === null) {
    throw new Error('Invalid upload status data: data is not an object');
  }

  const normalizedStatus: UploadStatus = {
    id: '',
    newFile: true,
  };

  if (typeof data.id === 'string' && data.id.trim() !== '') {
    normalizedStatus.id = data.id.trim();
  } else if (typeof data.id === 'number') {
    normalizedStatus.id = String(data.id);
  } else {
    normalizedStatus.id = `unknown-${Date.now()}`;
  }

  if (Object.prototype.hasOwnProperty.call(data, 'newFile')) {
    normalizedStatus.newFile = Boolean(data.newFile);
  }

  return normalizedStatus;
};

export const createFile = createAsyncThunk(
  '@@fileManager/createFile',
  async (
    { processUid, file, metadata, signal }: CreateFilePayload,
    { dispatch, rejectWithValue },
  ) => {
    dispatch(setBlobPublishProgress({ processUid, progress: 0 }));
    dispatch(setFileUploading({ processUid, uploading: true }));

    const retryChunkUpload = async (
      chunkFile: File,
      chunkMetadata: any,
      currentUploadedBytes: number,
      retries: number,
    ): Promise<UploadStatus> => {
      try {
        const rawResponse = await filesService.postFile(
          chunkFile,
          chunkMetadata,
          signal,
        );
        const responseText = await rawResponse.raw.text();

        if (responseText.length === 0) {
          return { id: 'default-id', newFile: true };
        }

        const parsedResponse = JSON.parse(responseText);
        return normalizeUploadStatus(parsedResponse);
      } catch (error) {
        if (retries > 0) {
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
          return retryChunkUpload(
            chunkFile,
            chunkMetadata,
            currentUploadedBytes,
            retries - 1,
          );
        }
        throw error;
      }
    };

    try {
      const totalChunks = Math.ceil(file.size / chunkSize);
      let uploadedBytes = 0;
      let lastChunkStatus: UploadStatus | null = null;

      for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
        const start = chunkIndex * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);

        const chunkFile = new File([chunk], file.name, {
          type: file.type,
          lastModified: file.lastModified,
        });

        const chunkMetadata = {
          ...metadata,
          filesize: file.size,
          index: chunkIndex,
          count: totalChunks,
        };

        lastChunkStatus = await retryChunkUpload(
          chunkFile,
          chunkMetadata,
          uploadedBytes,
          maxRetries,
        );

        uploadedBytes += chunk.size;
        const progress = Math.round((uploadedBytes / file.size) * 100);
        dispatch(setBlobPublishProgress({ processUid, progress }));
      }

      if (!lastChunkStatus) {
        throw new Error(
          'File upload failed: No chunks were uploaded successfully',
        );
      }

      await metadataService.patchEntityMetadata(
        lastChunkStatus.id as string,
        'starred',
        'false',
      );

      dispatch(setBlobPublishProgress({ processUid, progress: 100 }));
      dispatch(setFileUploading({ processUid, uploading: false }));
      dispatch(getChildEntities({ entityId: metadata.target }));

      toast.success(`File ${file.name} has been created.`);

      return { progressStatus: lastChunkStatus, name: file.name };
    } catch (error) {
      dispatch(setBlobPublishProgress({ processUid, progress: -1 }));
      dispatch(setFileUploading({ processUid, uploading: false }));

      let errorMessage = 'An unknown error occurred';
      if (error instanceof Error) {
        errorMessage = `${error.name}: ${error.message}`;
      } else if (typeof error === 'string') {
        errorMessage = error;
      }

      toast.error(
        `Failed to create file: ${file.name}. Error: ${errorMessage}`,
      );
      return rejectWithValue(`Failed to create file: ${errorMessage}`);
    }
  },
);

export const editEntityMetadata = createAsyncThunk(
  '@@fileManager/editEntityMetadata',
  async (
    { entityId, key, value }: EditEntityMetadataPayload,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await metadataService.patchEntityMetadata(
        entityId,
        key,
        value,
      );
      if (response) {
        const parentEntity = await filesService.fetchEntityParent(entityId);
        dispatch(
          getChildEntities({
            entityId: parentEntity,
          }),
        );
        toast.success('Entity metadata updated.');
        return entityId;
      } else {
        return rejectWithValue('Failed to edit entity metadata.');
      }
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to edit entity metadata.');
    }
  },
);

export const createFolder = createAsyncThunk(
  '@@fileManager/createFolder',
  async (
    payload: CreateFolderWithTypeRequest,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const folder = await filesService.postFolder(payload);

      metadataService.patchEntityMetadata(folder, 'starred', 'true');
      if (payload.type !== FolderTypeEnum.GENERAL)
        await filesService.patchFolderType(folder, payload.type);

      dispatch(
        getChildEntities({
          entityId: payload.entity,
        }),
      );

      toast.success(`Folder ${payload.newItemSpec.name} has been created.`);
      return folder;
    } catch (e) {
      console.error(e);
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const getTrashEntities = createAsyncThunk(
  '@@fileManager/getTrashEntities',
  async (workspaceId: string, { rejectWithValue }) => {
    try {
      const workspaceTrashFolderId =
        await workspacesService.fetchWorkspaceTrash(workspaceId);

      if (!workspaceTrashFolderId) {
        return rejectWithValue("Couldn't find trash folder for workspace");
      }

      const childEntities = await filesService.fetchChildEntities(
        workspaceTrashFolderId,
      );

      if (!childEntities.items)
        return rejectWithValue(
          `No child entities found for root entity ${workspaceTrashFolderId}`,
        );

      return childEntities.items?.map((item) => ({
        ...item,
        id: folderTypes.includes(item.type as string)
          ? item.folderId
          : item.fileId,
        svg:
          item.type === 'folder'
            ? 'folder'
            : item.type === 'calendar'
            ? 'calendar'
            : item.type === 'gallery'
            ? 'gallery'
            : item.contentType,
        metadata: {
          starred: 'false',
        },
      })) as Entity[];
    } catch (e) {
      console.error((e as Error).message);
      return rejectWithValue(
        'Failed to fetch trash files and folders for workspace.',
      );
    }
  },
);

export const deleteFile = createAsyncThunk(
  '@@fileManager/deleteFile',
  async (
    { entityId, t }: { entityId: string; t: (key: string) => string },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const { lastOpenedWorkspace } = (getState() as AppState).workspaces;
      if (!lastOpenedWorkspace) return rejectWithValue('No workspace found');
      const { bulkAction } = (getState() as AppState).fileManager;

      const entityParentId = await filesService.fetchEntityParent(entityId);
      const deletedFileStatus = await filesService.deleteFile(entityId);
      // TODO: Determine whether on the root level or a child level
      dispatch(
        getChildEntities({
          entityId: entityParentId,
        }),
      );

      const lastOpenedEntity = (getState() as AppState).fileManager
        .lastOpenedEntity;
      const entityType = lastOpenedEntity?.folderId ? 'folder' : 'file';
      let successMessage = '';
      if (!bulkAction) {
        if (deletedFileStatus) {
          successMessage =
            entityType === 'folder'
              ? t('FILE_MANAGER.FILES.DELETE_FOLDER_SUCCESS')
              : t('FILE_MANAGER.FILES.DELETE_FILE_SUCCESS');
          return { entityId, successMessage };
        }
      }
    } catch (e) {
      console.error((e as Error).message);

      const lastOpenedEntity = (getState() as AppState).fileManager
        .lastOpenedEntity;
      const entityType = lastOpenedEntity?.folderId ? 'folder' : 'file';

      const errorMessage =
        entityType === 'folder'
          ? t('FILE_MANAGER.FILES.DELETE_FOLDER_FAILURE')
          : t('FILE_MANAGER.FILES.DELETE_FILE_FAILURE');

      return rejectWithValue({ message: errorMessage });
    }
  },
);

export const starEntity = createAsyncThunk(
  '@@fileManager/starEntity',
  async (entityId: string, { rejectWithValue }) => {
    try {
      const response = await metadataService.patchEntityMetadata(
        entityId,
        'starred',
        'true',
      );
      if (response) {
        return entityId;
      } else {
        return rejectWithValue('Failed to star entity.');
      }
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to star entity.');
    }
  },
);

export const restoreFile = createAsyncThunk(
  '@@fileManager/restoreFile',
  async (entityId: string, { getState, rejectWithValue, dispatch }) => {
    try {
      // TODO: Refactor to take in destination folder id. For now its hardcoded to root workspaceId
      const { lastOpenedWorkspace: workspaceId, workspaces } = (
        getState() as AppState
      ).workspaces;
      if (!workspaceId) return rejectWithValue('No workspace found');
      const currentWorkspace = workspaces.find(
        (workspace) => workspace.id === workspaceId,
      );
      if (!currentWorkspace) return rejectWithValue('No workspace found');
      const workspaceRoot = currentWorkspace.root;
      if (!workspaceRoot) return rejectWithValue('No workspace root found');
      const response = await filesService.restoreFile({
        entity: entityId,
        destination: workspaceRoot,
      });
      dispatch(getTrashEntities(workspaceId));
      return response;
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to restore file.');
    }
  },
);

export const emptyTrash = createAsyncThunk(
  '@@fileManager/emptyTrash',
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const { lastOpenedWorkspace: workspaceId } = (getState() as AppState)
        .workspaces;
      if (!workspaceId) return rejectWithValue('No workspace found');
      await workspacesService.deleteTrash({
        workspaceId,
      });

      // Fetch updated trash entities after successfully emptying trash
      const response = await dispatch(getTrashEntities(workspaceId));
      if (getTrashEntities.rejected.match(response)) {
        return rejectWithValue('Failed to fetch updated trash entities.');
      }
      return response.payload; // Return updated trash entities
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to empty trash.');
    }
  },
);

export const protectionTag = createAsyncThunk(
  '@@fileManager/protectionTag',
  async (payload: SetProtectionLevelRequest, { rejectWithValue }) => {
    try {
      const response = await filesService.protectionTag({
        entity: payload.entity,
        protectionSpec: payload.protectionSpec,
      });
      return response;
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to set Protection level.');
    }
  },
);

export const unstarEntity = createAsyncThunk(
  '@@fileManager/unstarEntity',
  async (entityId: string, { rejectWithValue }) => {
    try {
      const response = await metadataService.patchEntityMetadata(
        entityId,
        'starred',
        'false',
      );
      if (response) {
        return entityId;
      } else {
        return rejectWithValue('Failed to star entity.');
      }
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to star entity.');
    }
  },
);

export const copyEntity = createAsyncThunk(
  '@@fileManager/copyEntity',
  async (payload: CopyToRequest, { dispatch, rejectWithValue, getState }) => {
    try {
      const response = await filesService.copyEntity(payload);
      const { bulkPaste } = (getState() as AppState).fileManager;
      if (response) {
        if (!bulkPaste) {
          toast.success(t('FILE_MANAGER.COPY.PASTED'));
        }
        dispatch(
          getChildEntities({
            entityId: payload.destination,
          }),
        );
        return response;
      } else {
        toast.error(t('FILE_MANAGER.COPY.FAILED'));
        return rejectWithValue(t('FILE_MANAGER.COPY.FAILED'));
      }
    } catch (e) {
      console.error(e);
      return rejectWithValue(t('FILE_MANAGER.COPY.FAILED'));
    }
  },
);

export const moveEntity = createAsyncThunk(
  '@@fileManager/moveEntity',
  async (
    payload: CopyToRequestWithId,
    { dispatch, rejectWithValue, getState },
  ) => {
    try {
      const { bulkAction } = (getState() as AppState).fileManager;
      const response = await filesService.moveEntity(payload);
      if (response) {
        // TODO: Refactor to determine whether the entity is on the root level or a child level
        if (payload.folderId && !bulkAction) {
          toast.success(t('FILE_MANAGER.MOVE.MOVED'));
          dispatch(
            getChildEntities({
              entityId: payload.folderId,
            }),
          );
        }
        return response;
      } else {
        toast.error(t('FILE_MANAGER.MOVE.FAILED'));
        return rejectWithValue(t('FILE_MANAGER.MOVE.FAILED'));
      }
    } catch (e) {
      console.error(e);
      return rejectWithValue(t('FILE_MANAGER.MOVE.FAILED'));
    }
  },
);

export const getEntityMetadata = createAsyncThunk(
  '@@fileManager/getEntityMetadata',
  async (entityId: string, { rejectWithValue }) => {
    try {
      const response = await metadataService.fetchMetadata<EntityMetadata>(
        entityId,
      );
      return response;
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to fetch entity.');
    }
  },
);

export const deleteEntityMetadata = createAsyncThunk(
  '@@fileManager/deleteEntityMetadata',
  async (payload: DeleteMetadataRequest, { dispatch, rejectWithValue }) => {
    try {
      const response = await metadataService.deleteMetadata(payload);
      dispatch(getEntityMetadata(payload.entity));
      return response;
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to delete metadata.');
    }
  },
);

export const setEntityMetadata = createAsyncThunk(
  '@@fileManager/setEntityMetadata',
  async (
    payload: {
      entity: string;
      key: string;
      value: string;
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await metadataService.patchEntityMetadata(
        payload.entity,
        payload.key,
        payload.value,
      );
      dispatch(getEntityMetadata(payload.entity));
      return response;
    } catch (e) {
      console.error(e);
      return rejectWithValue('Failed to set metadata.');
    }
  },
);

export const fileManagerSlice = createSlice({
  name: '@@fileManager',
  initialState,
  reducers: {
    setQueuedFiles: (state, action: PayloadAction<FileWithPath[]>) => {
      state.queuedFiles = action.payload;
    },
    setDisplayCreateFileModal: (state, action: PayloadAction<boolean>) => {
      state.displayCreateFileModal = action.payload;
    },
    setDisplayCreateFolderModal: (state, action: PayloadAction<boolean>) => {
      state.displayCreateFolderModal = action.payload;
    },
    setLastOpenedEntity: (state, action: PayloadAction<Entity | null>) => {
      state.lastOpenedEntity = action.payload;
    },
    setDisplayBulkDistributeModal: (state, action: PayloadAction<boolean>) => {
      state.displayBulkDistributeModal = action.payload;
    },
    setDisplayBulkDeleteEntity: (state, action: PayloadAction<boolean>) => {
      state.displayBulkDeleteEntityModal = action.payload;
    },
    setDisplayMoveEntityModal: (state, action: PayloadAction<boolean>) => {
      state.displayMoveEntityModal = action.payload;
    },
    setBulkPaste: (state, action: PayloadAction<boolean>) => {
      state.bulkPaste = action.payload;
    },
    setBulkAction: (state, action: PayloadAction<boolean>) => {
      state.bulkAction = action.payload;
    },
    setDisplayConvertConfirm: (state, action: PayloadAction<boolean>) => {
      state.displayConvertConfirmModal = action.payload;
    },
    setDisplayDistributeEntityModal: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.displayDistributeEntityModal = action.payload;
    },
    setBlobs: (state, action) => {
      state.entities = action.payload;
    },
    setPlan: (state, action) => {
      state.plan = action.payload;
    },
    setBlobPublishProgress: (
      state,
      action: PayloadAction<{
        processUid: string;
        progress: number;
      }>,
    ) => {
      const { processUid, progress } = action.payload;
      state.entityCreationProgress = {
        ...state.entityCreationProgress,
        [processUid]: progress,
      };
    },
    setFileUploading: (
      state,
      action: PayloadAction<{
        processUid: string;
        uploading: boolean;
      }>,
    ) => {
      const { processUid, uploading } = action.payload;
      state.fileUploading = {
        ...state.fileUploading,
        [processUid]: uploading,
      };
    },

    setDisplayType: (state, action) => {
      state.displayType = action.payload;
    },
    resetErrorMessage: (state, action) => {
      state.error = action.payload;
    },
    // TODO: Replace with async thunk
    setEntityQuery: (state, action) => {
      state.entityQuery = action.payload;
    },
    setContentHeight: (state, action) => {
      state.contentHeight = action.payload;
    },
    setDisplayMobileNavbar: (state, action: PayloadAction<boolean>) => {
      state.displayMobileNavbar = action.payload;
    },
    setDisplayRemoveEntityModal: (state, action: PayloadAction<boolean>) => {
      state.displayRemoveEntityModal = action.payload;
    },
    toggleDisplayMobileRecoveryFilter: (state) => {
      state.displayMobileRecoveryFilter = !state.displayMobileRecoveryFilter;
    },
    toggleDisplayPreviewFileModal: (state) => {
      state.displayPreviewFileModal = !state.displayPreviewFileModal;
    },
    setDisplayPreviewFileModal: (state, action: PayloadAction<boolean>) => {
      state.displayPreviewFileModal = action.payload;
    },
    toggleDisplayMobileFinder: (state) => {
      state.displayMobileFinder = !state.displayMobileFinder;
    },
    toggleDisplayMobileNavbar: (state) => {
      state.displayMobileNavbar = !state.displayMobileNavbar;
    },
    toggleDisplayQuickNavigation: (state) => {
      state.displayQuickNavigation = !state.displayQuickNavigation;
    },
    toggleDisplayRenameEntityModal: (state) => {
      state.displayRenameEntityModal = !state.displayRenameEntityModal;
    },
    setDisplayMetadataModal: (state, action: PayloadAction<boolean>) => {
      state.displayMetadataModal = action.payload;
    },
    toggleDisplayCreateFileModal: (state) => {
      state.displayCreateFileModal = !state.displayCreateFileModal;
    },
    setLastSelectedEntityId: (state, action: PayloadAction<string | null>) => {
      state.lastSelectedEntityId = action.payload;
    },
    setSelectedEntityIds: (state, action: PayloadAction<string[]>) => {
      state.selectedEntityIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFolder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createFolder.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FilesErrorCodesEnum.NAME_EXISTS_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(createFolder.fulfilled, (state, action) => {
        state.loading = false;
        state.displayCreateFolderModal = false;
        // state.entities = action.payload;
        state.error = null;
      });
    builder
      .addCase(getWorkspaceEntities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWorkspaceEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
        state.error = null;
      })
      .addCase(getWorkspaceEntities.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FilesErrorCodesEnum.GET_ENTITIES_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(protectionTag.pending, (state) => {
        state.loading = true;
        state.changedSecurityLevel = false;
        state.error = null;
      })
      .addCase(protectionTag.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.changedSecurityLevel = true;
          state.error = null;
        } else {
          state.error = {
            code: FilesErrorCodesEnum.SET_PROTECTION_LEVEL_FAILED,
            message: 'Failed to change Protection tag. Please try again.',
          };
        }
        state.error = null;
      })
      .addCase(protectionTag.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FilesErrorCodesEnum.SET_PROTECTION_LEVEL_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(copyEntity.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(copyEntity.fulfilled, (state, action) => {
        state.loading = false;
        // TODO: Indicator that the copy operation has completed
        state.error = null;
      })
      .addCase(copyEntity.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FilesErrorCodesEnum.COPY_ENTITY_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(moveEntity.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(moveEntity.fulfilled, (state, action) => {
        state.loading = false;
        // TODO: Indicator that the edit operation has completed
        state.error = null;
      })
      .addCase(moveEntity.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FilesErrorCodesEnum.MOVE_ENTITY_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(getEntitiesMetadata.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEntitiesMetadata.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = state.entities.map((blob) => {
          const metadata = action.payload.find(
            (metadata) => metadata.id === blob.id,
          );
          return {
            ...blob,
            metadata,
          };
        });
        state.error = null;
      })
      .addCase(getEntitiesMetadata.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FilesErrorCodesEnum.GET_ENTITIES_METADATA_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(getTrashEntities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTrashEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.trashEntities = action.payload;
        state.error = null;
      })
      .addCase(getTrashEntities.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FilesErrorCodesEnum.GET_TRASH_ENTITIES_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(getEntityMetadata.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEntityMetadata.fulfilled, (state, action) => {
        state.loading = false;
        state.lastOpenedEntity = {
          ...state.lastOpenedEntity,
          id: action.payload.id!,
          svg: action.type,
          metadata: action.payload,
        };
        state.error = null;
      })
      .addCase(getEntityMetadata.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FilesErrorCodesEnum.GET_ENTITY_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(editEntityMetadata.pending, (state) => {
        state.loading = true;
        state.changedEntityName = false;
        state.error = null;
      })
      .addCase(editEntityMetadata.fulfilled, (state, action) => {
        state.loading = false;
        state.changedEntityName = true;
        // const payload = {
        //   ...state.lastOpenedEntity,
        //   name: action.payload,
        // }
        // payload[action.payload] = action.payload
        state.error = null;
      })
      .addCase(editEntityMetadata.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FilesErrorCodesEnum.EDIT_ENTITY_METADATA_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(starEntity.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(starEntity.fulfilled, (state, action) => {
        state.loading = false;
        const entityId = action.payload;
        const entity = state.entities.find((entity) => entity.id === entityId);
        if (entity && entity.metadata) {
          entity.metadata.starred = 'true';
        }
        state.error = null;
      })
      .addCase(starEntity.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FilesErrorCodesEnum.STAR_ENTITY_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(unstarEntity.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(unstarEntity.fulfilled, (state, action) => {
        state.loading = false;
        const entityId = action.payload;
        const entity = state.entities.find((entity) => entity.id === entityId);
        if (entity && entity.metadata) {
          entity.metadata.starred = 'false';
        }
        state.error = null;
      })
      .addCase(unstarEntity.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FilesErrorCodesEnum.STAR_ENTITY_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(getEntityParents.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEntityParents.fulfilled, (state, action) => {
        state.loading = false;
        state.entityParents = action.payload;
        state.error = null;
      })
      .addCase(getEntityParents.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FilesErrorCodesEnum.GET_ENTITY_PARENTS_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(convertDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(convertDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.convertedDocument = action.payload;
        toast.success(t('FILE_MANAGER.FILE_PREVIEW.CONVERT.TOAST_SUCCESS'));
        state.error = null;
      })
      .addCase(convertDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FilesErrorCodesEnum.GET_COVERTED_DOC_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(getEntityParentsForMove.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEntityParentsForMove.fulfilled, (state, action) => {
        state.loading = false;
        state.entityParentsForMove = action.payload;
        state.error = null;
      })
      .addCase(getEntityParentsForMove.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FilesErrorCodesEnum.GET_ENTITY_PARENTS_FAILED_FOR_MOVE,
          message: action.payload as string,
        };
      });
    builder
      .addCase(getEntityParent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEntityParent.fulfilled, (state, action) => {
        state.loading = false;
        state.entityParent = action.payload;
        state.error = null;
      })
      .addCase(getEntityParent.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FilesErrorCodesEnum.GET_ENTITY_PARENT_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(getChildEntities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getChildEntities.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.previewEntities) {
          state.previewEntities = action.payload.response;
        } else {
          state.entities = action.payload.response;
        }
        state.error = null;
      })
      .addCase(getChildEntities.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FilesErrorCodesEnum.GET_CHILD_ENTITIES_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(deleteFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload && 'successMessage' in action.payload) {
          const { successMessage, entityId } = action.payload;
          toast.success(successMessage);
          state.trashEntities = state.trashEntities.filter(
            (entity) => entity.id !== entityId,
          );
        }
        state.error = null;
      })
      .addCase(deleteFile.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FilesErrorCodesEnum.DELETE_ENTITY_FAILED,
          message: (action.payload as { message: string }).message,
        };
      });
    builder
      .addCase(restoreFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(restoreFile.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = null;
          toast.success('File restoration completed.');
        } else {
          state.error = {
            code: FilesErrorCodesEnum.RESTORE_ENTITY_FAILED,
            message: "Couldn't restore file. Please try again",
          };
        }
      })
      .addCase(restoreFile.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FilesErrorCodesEnum.RESTORE_ENTITY_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(emptyTrash.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(emptyTrash.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = null;
          state.trashEntities = [];
          toast.success('Trash emptying completed.');
        } else {
          state.error = {
            code: FilesErrorCodesEnum.EMPTY_TRASH_FAILED,
            message: "Couldn't empty trash. Please try again",
          };
        }
      })
      .addCase(emptyTrash.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: FilesErrorCodesEnum.EMPTY_TRASH_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(createFile.pending, (state, action) => {
        const { processUid } = action.meta.arg;
        state.loading = true;
        state.fileUploading[processUid] = true;
        state.error = null;
      })
      .addCase(createFile.rejected, (state, action) => {
        const { processUid } = action.meta.arg;
        state.loading = false;
        state.fileUploading[processUid] = false;
        state.error = {
          code: FilesErrorCodesEnum.CREATE_ENTITY_FAILED,
          message: action.payload as string,
        };
      })
      .addCase(createFile.fulfilled, (state, action) => {
        const { processUid } = action.meta.arg;
        state.loading = false;
        state.fileUploading[processUid] = false;
        // state.blobs = action.payload;
        state.error = null;
        // state.queuedFiles = state.queuedFiles.filter(
        //   (file) => file.name !== action.payload.name,
        // );
        // set created file state here
        // const { uploadId } = action.meta.arg;
        // delete state.uploadProgress[uploadId];
      });
  },
});

export const {
  setPlan,
  setBlobs,
  setBulkPaste,
  setBulkAction,
  setEntityQuery,
  setQueuedFiles,
  setDisplayType,
  setContentHeight,
  resetErrorMessage,
  setLastOpenedEntity,
  setSelectedEntityIds,
  setBlobPublishProgress,
  setFileUploading,
  setDisplayMoveEntityModal,
  setDisplayMobileNavbar,
  setDisplayConvertConfirm,
  setDisplayBulkDeleteEntity,
  setDisplayRemoveEntityModal,
  setDisplayBulkDistributeModal,
  setDisplayDistributeEntityModal,
  setLastSelectedEntityId,
  toggleDisplayMobileNavbar,
  setDisplayCreateFileModal,
  toggleDisplayMobileFinder,
  setDisplayMetadataModal,
  setDisplayPreviewFileModal,
  setDisplayCreateFolderModal,
  toggleDisplayQuickNavigation,
  toggleDisplayCreateFileModal,
  toggleDisplayPreviewFileModal,
  toggleDisplayRenameEntityModal,
  toggleDisplayMobileRecoveryFilter,
} = fileManagerSlice.actions;

export const fileManagerReducer = fileManagerSlice.reducer;
