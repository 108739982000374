import {
  DirectPermissionResult,
  GetUserPermissionsRequest,
  SetPermissionRequest,
} from '@oproma/prividox-orchestration-open-api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { extractApiError, permissionsService } from '../api';
import { getWorkspaceGroups } from '../groups';
import { getWorkspaceMembers } from '../members';
import { AppState } from '../store';
import {
  DirectPermissionResultWithType,
  IPermissionsState,
  PermissionsErrorCodesEnum,
  PermissionsPayload,
} from './types';

const initialState: IPermissionsState = {
  loading: false,
  error: null,
  permissions: [],
  memberEntityPermissions: {},
  groupEntityPermissions: {},
  displayPermissionsModal: false,
  displayUnsavedChangesModal: false,
  displayMemberFinderForPermissions: false,
  displayGroupFinderForPermissions: false,
};

export const getWorkspacePermissions = createAsyncThunk(
  '@@permissions/getWorkspacePermissions',
  async (
    payload: PermissionsPayload,
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      await Promise.all([
        dispatch(
          getWorkspaceGroups({
            workspaceId: payload.workspaceId,
          }),
        ),
        dispatch(getWorkspaceMembers(payload.workspaceId)),
      ]);

      const { members } = (getState() as AppState).members;
      const { groups } = (getState() as AppState).groups;

      // TODO: check for more (groups are paginated)
      if (payload.entityId === null) {
        const response = await Promise.allSettled([
          ...members.map((member) => {
            return permissionsService.fetchMemberPermission({
              entity: payload.workspaceId,
              userId: member.id!,
            });
          }),
          ...(groups ?? []).map((group) => {
            return permissionsService.fetchGroupPermission({
              entity: payload.workspaceId,
              userId: group.id!,
            });
          }),
        ]);

        const permissions: DirectPermissionResultWithType[] = response
          .filter((result) => result.status === 'fulfilled')
          .map((result) => ({
            ...(result as PromiseFulfilledResult<DirectPermissionResult>).value,
            // TODO: Fragile logic as it depends on email field being present and still reusing it for group and member permissions
            permissionType: (
              result as PromiseFulfilledResult<DirectPermissionResult>
            ).value.email
              ?.toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              )
              ? 'member'
              : 'group',
          }));
        return permissions;
      } else if (payload.entityId) {
        const response = await Promise.allSettled([
          ...members.map((member) => {
            return permissionsService.fetchMemberPermission({
              entity: payload.entityId as string,
              userId: member.id!,
            });
          }),
          ...(groups ?? []).map((group) => {
            return permissionsService.fetchGroupPermission({
              entity: payload.entityId as string,
              userId: group.id!,
            });
          }),
        ]);

        const permissions: DirectPermissionResultWithType[] = response
          .filter((result) => result.status === 'fulfilled')
          .map((result) => ({
            ...(result as PromiseFulfilledResult<DirectPermissionResult>).value,
            // TODO: Fragile logic as it depends on email field being present and still reusing it for group and member permissions
            permissionType: (
              result as PromiseFulfilledResult<DirectPermissionResult>
            ).value.email
              ?.toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              )
              ? 'member'
              : 'group',
          }));
        return permissions;
      }
    } catch (e) {
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

// TODO: Simplify logic
export const getMemberPermission = createAsyncThunk(
  '@@permissions/getMemberPermission',
  async (payload: GetUserPermissionsRequest, { rejectWithValue }) => {
    try {
      const response = await permissionsService.fetchMemberPermission(payload);
      return response;
    } catch (e) {
      console.error(e);
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const getGroupPermission = createAsyncThunk(
  '@@permissions/getGroupPermission',
  async (payload: GetUserPermissionsRequest, { rejectWithValue }) => {
    try {
      const response = await permissionsService.fetchGroupPermission(payload);
      return response;
    } catch (e) {
      console.error(e);
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

export const editMemberPermission = createAsyncThunk(
  '@@permissions/editMemberPermission',
  async (
    payload: SetPermissionRequest,
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const response = await permissionsService.patchMemberPermission(payload);
      const { lastOpenedWorkspace: workspaceId } = (getState() as AppState)
        .workspaces;
      dispatch(
        getWorkspacePermissions({
          workspaceId: workspaceId as string,
          entityId: payload.entity,
        }),
      );
      return response;
    } catch (e) {
      console.error(e);
      return rejectWithValue(extractApiError(e as Error));
    }
  },
);

const permissionsSlice = createSlice({
  name: '@@permissions',
  initialState,
  reducers: {
    toggleDisplayPermissionsModal: (state) => {
      state.displayPermissionsModal = !state.displayPermissionsModal;
    },
    toggleDisplayUnsavedChangesModal: (state) => {
      state.displayUnsavedChangesModal = !state.displayUnsavedChangesModal;
    },
    setDisplayGroupFinderForPermissions: (state, action) => {
      state.displayGroupFinderForPermissions = action.payload;
    },
    setDisplayMemberFinderForPermissions: (state, action) => {
      state.displayMemberFinderForPermissions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkspacePermissions.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWorkspacePermissions.fulfilled, (state, action) => {
        state.loading = false;
        state.permissions = action.payload as DirectPermissionResultWithType[];
      })
      .addCase(getWorkspacePermissions.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: PermissionsErrorCodesEnum.GET_MEMBER_PERMISSION_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(editMemberPermission.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editMemberPermission.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(editMemberPermission.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: PermissionsErrorCodesEnum.EDIT_MEMBER_PERMISSION_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(getGroupPermission.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getGroupPermission.fulfilled, (state, action) => {
        state.loading = false;
        state.groupEntityPermissions = action.payload;
        state.error = null;
      })
      .addCase(getGroupPermission.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: PermissionsErrorCodesEnum.FETCH_GROUP_PERMISSION_FAILED,
          message: action.payload as string,
        };
      });
    builder
      .addCase(getMemberPermission.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMemberPermission.fulfilled, (state, action) => {
        state.loading = false;
        state.memberEntityPermissions = action.payload;
        state.error = null;
      })
      .addCase(getMemberPermission.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          code: PermissionsErrorCodesEnum.FETCH_MEMBER_PERMISSION_FAILED,
          message: action.payload as string,
        };
      });
  },
});

export const {
  toggleDisplayPermissionsModal,
  toggleDisplayUnsavedChangesModal,
  setDisplayGroupFinderForPermissions,
  setDisplayMemberFinderForPermissions,
} = permissionsSlice.actions;

export const permissionsReducer = permissionsSlice.reducer;
