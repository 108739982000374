import {
  Block,
  BlockHead,
  BlockTitle,
  Button,
  CardSidebar,
  Content,
  Head,
  Icon,
  Layout,
  Loader,
  MemberContributions,
  MemberHeader,
  MemberHeatmap,
  UserAvatar,
  findUpper,
} from '@oproma/core-components';
import { useTranslation } from '@oproma/i18n';
import {
  getWorkspaceMember,
  getWorkspaceMemberReport,
  getWorkspaceMembers,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useParams } from '@oproma/router';
import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Alert, Card, CardBody, Nav, NavItem, Spinner } from 'reactstrap';

const MemberPage = () => {
  const dispatch = useAppDispatch();
  const { workspaceId, memberId } = useParams();
  const [displayMemberBio, setDisplayMemberBio] = useState(false);
  const { member, members, loading, error } = useAppSelector(
    (state) => state.members,
  );
  const foundMember = members.find((m) => m.id === member?.id);
  const [tab, setTab] = useState(1);
  const { t } = useTranslation();

  const [membersLoading, setMembersLoading] = useState(true);

  useEffect(() => {
    if (memberId && workspaceId) {
      dispatch(getWorkspaceMember(memberId as string));
      dispatch(getWorkspaceMembers(workspaceId as string));
      dispatch(
        getWorkspaceMemberReport({
          project: workspaceId as string,
          user: memberId as string,
          start: moment().subtract(1, 'year').valueOf(),
          end: moment().valueOf(),
        }),
      );
    }
  }, [workspaceId, memberId]);

  useEffect(() => {
    if (members && members.length > 0) {
      setMembersLoading(false);
    }
  }, [members]);

  // useEffect(() => {
  //   const tab = router.query.tab;
  //   if (!tab) {
  //     const searchParams = new URLSearchParams(window.location.search);
  //     searchParams.set('tab', 'personal');
  //     // window.location.search = searchParams.toString();
  //   }
  // }, [router]);

  const toggle = () => setDisplayMemberBio(!displayMemberBio);

  if (loading) return <Loader />;

  if (error)
    return (
      <Alert className="alert-icon" color="danger">
        <Icon name="cross-circle" />
        {error.message}
      </Alert>
    );

  return (
    <>
      <Head heading={`Member ${member?.name}`} />
      <Layout>
        <Content>
          <MemberHeader />
          <Block>
            <Card className="card-bordered">
              <div className="card-aside-wrap" id="user-detail-block">
                <div className="card-content">
                  <Nav tabs className="nav-tabs-mb-icon nav-tabs-card">
                    <NavItem>
                      <a
                        className={clsx('nav-link', {
                          active: tab === 1,
                          // || routeTab === 'personal',
                        })}
                        href="#personal"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setTab(1);
                        }}
                      >
                        <Icon name="user-circle"></Icon>
                        <span>{t('MEMBERS.MEMBERID.PERSONAL_TAB')}</span>
                      </a>
                    </NavItem>
                    <NavItem>
                      <a
                        className={clsx('nav-link', {
                          active: tab === 2,
                          // || routeTab === 'contributions',
                        })}
                        href="#contributions"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setTab(2);
                        }}
                      >
                        <Icon name="star"></Icon>
                        <span>{t('MEMBERS.MEMBERID.CONTRIBUTIONS_TAB')}</span>
                      </a>
                    </NavItem>
                    <NavItem className="nav-item-trigger d-xxl-none">
                      <Button
                        className={`toggle btn-icon btn-trigger ${
                          displayMemberBio && 'active'
                        }`}
                        onClick={toggle}
                      >
                        <Icon name="user-list-fill"></Icon>
                      </Button>
                    </NavItem>
                  </Nav>
                  <div className="card-inner">
                    {tab === 1 && (
                      <>
                        <Block>
                          <BlockHead>
                            <BlockTitle tag="h5">
                              {t('MEMBERS.MEMBERID.PERSONAL.TITLE')}
                            </BlockTitle>
                            <p>{t('MEMBERS.MEMBERID.PERSONAL.HEADING')}</p>
                          </BlockHead>
                          <div className="profile-ud-list">
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  {t('MEMBERS.MEMBERID.PERSONAL.FULL_NAME')}
                                </span>
                                <span className="profile-ud-value">
                                  {member?.name}
                                </span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  {t('MEMBERS.MEMBERID.PERSONAL.PRESENCE')}
                                </span>
                                {membersLoading ? (
                                  <span
                                    className={clsx(
                                      'tb-status',
                                      'profile-ud-value',
                                    )}
                                  >
                                    <Spinner size="sm" />
                                  </span>
                                ) : (
                                  <span
                                    className={clsx(
                                      'tb-status',
                                      'profile-ud-value',
                                      {
                                        'text-success': foundMember?.online,
                                        'text-danger': !foundMember?.online,
                                      },
                                    )}
                                  >
                                    {foundMember?.online
                                      ? t('MEMBERS.MEMBERID.PERSONAL.ONLINE')
                                      : t('MEMBERS.MEMBERID.PERSONAL.OFFLINE')}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  {t('MEMBERS.MEMBERID.PERSONAL.MOBILE')}
                                </span>
                                <span className="profile-ud-value">
                                  {member?.phone ?? 'No Phone'}
                                </span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  {t('MEMBERS.MEMBERID.PERSONAL.EMAIL')}
                                </span>
                                <span className="profile-ud-value">
                                  {member?.email}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Block>
                        <Block>
                          <BlockHead className="nk-block-head-line">
                            <BlockTitle
                              tag="h6"
                              className="overline-title text-base"
                            >
                              {t('MEMBERS.MEMBERID.PERSONAL.MORE')}
                            </BlockTitle>
                          </BlockHead>
                          <div className="profile-ud-list">
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  {t('MEMBERS.MEMBERID.PERSONAL.TAGLINE')}
                                </span>
                                <span className="profile-ud-value">
                                  {member?.tagline ?? 'No Tagline'}
                                </span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  {t('MEMBERS.MEMBERID.PERSONAL.LOCATION')}
                                </span>
                                <span className="profile-ud-value">
                                  {member?.address ?? 'No Location'}
                                </span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  {t('MEMBERS.MEMBERID.PERSONAL.TIME_FORMAT')}
                                </span>
                                <span className="profile-ud-value">
                                  {member?.['app.settings.timeFormat'] ??
                                    'No Time Format'}
                                </span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  {t('MEMBERS.MEMBERID.PERSONAL.DATE_FORMAT')}
                                </span>
                                <span className="profile-ud-value">
                                  {member?.['app.settings.dateFormat'] ??
                                    'No Date Format'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Block>
                      </>
                    )}
                    {tab === 2 && (
                      <Block>
                        <BlockHead>
                          <BlockTitle tag="h5">
                            {t('MEMBERS.MEMBERID.CONTRIBUTON.TITLE')}
                          </BlockTitle>
                          <p>{t('MEMBERS.MEMBERID.CONTRIBUTON.HEADING')}</p>
                        </BlockHead>

                        <Card className="card-bordered">
                          <CardBody className="card-inner">
                            <MemberHeatmap />
                            <MemberContributions />
                          </CardBody>
                        </Card>

                        {/* <div className="bq-note">
                      {noteData.map((item) => (
                        <div className="bq-note-item" key={item.id}>
                          <div className="bq-note-text">
                            <p>{item.text}</p>
                          </div>
                          <div className="bq-note-meta">
                            <span className="bq-note-added">
                              Added on <span className="date">{item.date}</span>{' '}
                              at <span className="time">{item.time} PM</span>
                            </span>
                            <span className="bq-note-sep sep">|</span>
                            <span className="bq-note-by">
                              By <span>{item.company}</span>
                            </span>
                            <a
                              href="#deletenote"
                              onClick={(ev) => {
                                ev.preventDefault();
                                deleteNote(item.id);
                              }}
                              className="link link-sm link-danger"
                            >
                              Delete Note
                            </a>
                          </div>
                        </div>
                      ))}
                    </div> */}
                      </Block>
                    )}
                  </div>
                </div>

                <CardSidebar toggleState={displayMemberBio}>
                  <div className="card-inner">
                    <div className="user-card user-card-s2 mt-xxl-0 mt-5">
                      <UserAvatar
                        className="lg"
                        theme="primary"
                        text={findUpper(member?.name)}
                      />
                      <div className="user-info">
                        {/* <Badge color="outline-light" pill className="ucap">
                        {member?.subscribed ?? 'No Subscribed'}
                      </Badge> */}
                        <h5>{member?.name}</h5>
                        <span className="sub-text">{member?.email}</span>
                      </div>
                    </div>
                  </div>
                </CardSidebar>
                {displayMemberBio && (
                  <div
                    className="toggle-overlay"
                    onClick={() => toggle()}
                  ></div>
                )}
              </div>
            </Card>
          </Block>
        </Content>
      </Layout>
    </>
  );
};

export default MemberPage;
