import { useTranslation } from '@oproma/i18n';
import {
  Entity,
  addStarredEntity,
  moveEntity,
  removeStarredEntity,
  setLastOpenedEntity,
  setLastSelectedEntityId,
  setSelectedEntityIds,
  toggleDisplayPreviewFileModal,
  useAppDispatch,
  useAppSelector,
} from '@oproma/prividox-store';
import { useNavigate, useParams } from '@oproma/router';
import clsx from 'clsx';
import {
  MouseEvent,
  MouseEventHandler,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import { Input, Tooltip } from 'reactstrap';
import {
  ContextMenuPosition,
  ContextMenuState,
  useContextMenu,
} from '../hooks';
import { Icon } from '../icon.component';
import { prettyBytes, trimFilename } from '../utils';
import {
  FileManagerDropdown,
  LayoutType,
} from './file-manager-dropdown.component';
import {
  EntityThumbnail,
  getEntityThumbnail,
} from './svg-thumbnails.component';

type FileProps = {
  entity: Entity;
  entityDisplayType: string;
  isSelected: boolean;
  onSelectEntity: (
    entityId: string,
    isCtrlClick: boolean,
    isShiftClick: boolean,
  ) => void;
  layoutType?: LayoutType;
  onRightClick: (entityId: string, position: ContextMenuPosition) => void;
  contextMenu: ContextMenuState;
  closeContextMenu: () => void;
};

const EntityComponent = memo(
  ({
    entity,
    entityDisplayType,
    layoutType,
    isSelected,
    onSelectEntity,
    onRightClick,
    contextMenu,
    closeContextMenu,
  }: FileProps) => {
    const navigate = useNavigate();
    const { workspaceId, entityId } = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { starred } = useAppSelector((state) => state.starred);
    const { timeFormat } = useAppSelector((state) => state.user);
    const { selectedEntityIds } = useAppSelector((state) => state.fileManager);
    const [isChecked, setIsChecked] = useState(false);

    const [{ isDragging }, drag] = useDrag({
      type: 'entity',
      item: { id: entity.id ?? entity.folderId },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });

    const [{ canDrop, isOver }, drop] = useDrop({
      accept: 'entity',
      drop: (item: { id: string }) => {
        const droppedEntityId = item.id;
        const targetEntityId = entity.folderId;
        // Don't drop an entity onto itself
        if (droppedEntityId === targetEntityId) {
          return;
        }
        // Move the entity to the new location
        if (!droppedEntityId || !targetEntityId) {
          toast.error(t('FILE_MANAGER.TOASTS.LOCATION_MOVE_ERROR'));
          return;
        }
        dispatch(
          moveEntity({
            entity: droppedEntityId,
            destination: targetEntityId,
            folderId: entityId,
          }),
        );
      },
      collect: (monitor) => ({
        canDrop: !!monitor.canDrop(),
        isOver: !!monitor.isOver(),
      }),
    });

    const [tooltipOpen, setTooltipOpen] = useState<Record<string, boolean>>({});

    const toggle = useCallback(
      (id: string) => {
        setTooltipOpen({
          ...tooltipOpen,
          [id]: !tooltipOpen[id],
        });
      },
      [tooltipOpen],
    );

    // Merge the drag and drop refs
    const dragDropRef = (element: HTMLElement | null) => {
      drag(element);
      drop(element);
    };

    useEffect(() => {
      const isEntitySelected = selectedEntityIds.includes(entity.id);
      setIsChecked(isEntitySelected);
      if (!isEntitySelected) setIsChecked(false);
    }, [entityDisplayType, selectedEntityIds]);

    const onEntityClick = useCallback(
      (entity: Entity) => {
        if (
          entity.type === 'folder' ||
          entity.type === 'gallery' ||
          entity.type === 'calendar'
        ) {
          navigate(`/${workspaceId}/file-manager/entities/${entity.folderId}`);
        } else {
          dispatch(setLastOpenedEntity(entity));
          dispatch(toggleDisplayPreviewFileModal());
        }
      },
      [dispatch, navigate, workspaceId],
    );

    const formatDate = (date: number | undefined) => {
      return <Moment format="l">{date}</Moment>;
    };

    const formatTime = (timestamp: number | undefined, timeFormat: string) => {
      return timeFormat === 'HH:mm' ? (
        <Moment format="L HH:mm">{timestamp}</Moment>
      ) : (
        <Moment format="L HH:mm:ss">{timestamp}</Moment>
      );
    };

    const trimmedFilename = useMemo(
      () => trimFilename(entity.name as string),
      [entity.name],
    );
    const formattedDate = useMemo(
      () => formatDate(entity.modified),
      [entity.modified],
    );

    const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
      (event) => {
        const isShiftClick = event?.shiftKey;
        const isCtrlClick = event?.ctrlKey || event?.metaKey; // metaKey for macOS
        onSelectEntity(entity.id, isShiftClick, isCtrlClick);
      },
      [entity.id, onSelectEntity],
    );

    const handleContextMenu = (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      onRightClick(entity.id, { x: event.pageX, y: event.pageY });
      handleClick(event);
    };

    const displayContextMenu =
      contextMenu.display && contextMenu.activeEntityId === entity.id;

    const translatedFormatLabel = (data: string | undefined) => {
      switch (data) {
        case 'PROTECTED_A':
          return t('FILE_MANAGER.FILE_PREVIEW.SECURITY_LEVEL.PROTECTED_A');
        case 'NONE':
          return t('None');
        case 'PROTECTED_B':
          return t('FILE_MANAGER.FILE_PREVIEW.SECURITY_LEVEL.PROTECTED_B');
        default:
          return t('FILE_MANAGER.FILE_PREVIEW.SECURITY_LEVEL.NONE');
      }
    };
    return (
      <div
        onContextMenu={handleContextMenu}
        ref={dragDropRef}
        className={clsx('nk-file-item nk-file clickable-row', {
          'bg-light': isOver && canDrop && isDragging,
          selected: isSelected,
        })}
        onClick={handleClick}
        onDoubleClick={() => onEntityClick(entity)}
      >
        <div className="nk-file-info">
          <Input
            type="checkbox"
            className={`file-checkbox ${isChecked ? 'checked' : ''}`}
            checked={isChecked}
            onChange={(e) => {
              if (!isChecked) {
                setIsChecked(e.target.checked);
                dispatch(
                  setSelectedEntityIds([...selectedEntityIds, entity.id]),
                );
              } else if (isChecked) {
                setIsChecked(!isChecked);
                dispatch(
                  setSelectedEntityIds(
                    selectedEntityIds.filter((id: string) => id !== entity.id),
                  ),
                );
              }
            }}
          />
          <div className="nk-file-title">
            <div className="nk-file-icon">
              <span className="nk-file-icon-type">
                {getEntityThumbnail(entity.svg as EntityThumbnail)}
              </span>
            </div>
            <div className="nk-file-name">
              <div className="nk-file-name-text">
                {entity.subscribed === true && (
                  <>
                    <Icon
                      className="ni-bell-fill"
                      id={`subscribed-${entity.id}`}
                    ></Icon>
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpen[`subscribed-${entity.id}`]}
                      target={`subscribed-${entity.id}`}
                      toggle={() => toggle(`subscribed-${entity.id}`)}
                    >
                      {t('FILE_MANAGER.SUBSCRIPTION.TOOLTIP_SUBSCRIBED')}
                    </Tooltip>
                  </>
                )}
                <a
                  className="nk-file-link-non-block"
                  href="#link"
                  onClick={(ev) => ev.preventDefault()}
                  id={`name-${entity.id}`}
                  onDoubleClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <span className="title">{trimmedFilename}</span>
                </a>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen[entity.id]}
                  target={`name-${entity.id}`}
                  toggle={() => toggle(entity.id)}
                >
                  {entity.name}
                </Tooltip>

                {(entity?.level === 'PROTECTED_A' ||
                  entity?.level === 'PROTECTED_B') && (
                  <>
                    <Icon
                      className="ni-shield-check-fill"
                      id={`protected-${entity.id}`}
                    ></Icon>
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpen[`protected-${entity.id}`]}
                      target={`protected-${entity.id}`}
                      toggle={() => toggle(`protected-${entity.id}`)}
                    >
                      {translatedFormatLabel(entity?.level)}
                    </Tooltip>
                  </>
                )}
                <div className="asterisk">
                  <a
                    href="#folder"
                    onClick={(ev) => {
                      ev.preventDefault();
                      // fileManagerUpdate.toggleStarred(item.id);
                      if (starred.includes(entity.id)) {
                        dispatch(removeStarredEntity(entity.id));
                      } else {
                        dispatch(addStarredEntity(entity.id));
                      }
                    }}
                    className={clsx({
                      active: starred.includes(entity.id),
                    })}
                  >
                    <Icon className="asterisk-off icon ni ni-star"></Icon>
                    <Icon className="asterisk-on icon ni ni-star-fill"></Icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {(entityDisplayType === 'group' || entityDisplayType === 'grid') && (
            <ul className="nk-file-desc">
              <li id={`date-${entity.id}`} className="date">
                {formattedDate}
                <Tooltip
                  placement="bottom"
                  isOpen={tooltipOpen[`date-${entity.id}`]}
                  target={`date-${entity.id}`}
                  toggle={() => toggle(`date-${entity.id}`)}
                >
                  {formatTime(entity.modified, timeFormat)}
                </Tooltip>
              </li>
              {entity.type !== 'gallery' && entity.type !== 'calendar' && (
                <li className="size text-nowrap">
                  {/* {item.meta.type === 'folder'
                        ? getTotalSize(item)
                        : item.meta.size}{' '} */}
                  {prettyBytes(entity.size ?? 0)}
                </li>
              )}
              {entity.type === 'file' && <li>v{entity.numversion}</li>}
              {entity.creatorname && (
                <li className="members">{entity.creatorname}</li>
              )}
              {/* {item.meta.members && (
              <li className="members">{item.meta.members.length} Members</li>
            )} */}
            </ul>
          )}
        </div>
        {entityDisplayType === 'list' && (
          <>
            {layoutType === undefined && (
              <div className="nk-file-meta">
                <div className="tb-lead">
                  <div className="tb-lead">
                    {formatTime(entity.modified, timeFormat)}
                  </div>
                </div>
              </div>
            )}
            {layoutType === 'trash' && (
              <div className="nk-file-date">
                <div className="tb-lead">
                  <div className="tb-lead">
                    {formatTime(entity.modified, timeFormat)}
                  </div>
                </div>
              </div>
            )}

            <div className="nk-file-members">
              <div className="tb-lead">{entity.creatorname}</div>
            </div>
          </>
        )}
        <FileManagerDropdown
          entity={entity}
          layoutType={layoutType}
          display={displayContextMenu}
          position={contextMenu.position}
          onClose={closeContextMenu}
        />
      </div>
    );
  },
);

type FileManagerFilesProps = {
  entityDisplayType?: string;
  entities: Entity[];
  layoutType?: LayoutType;
};

export const FileManagerFiles = ({
  entityDisplayType,
  entities,
  layoutType,
}: FileManagerFilesProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { displayType, lastSelectedEntityId, selectedEntityIds } =
    useAppSelector((state) => state.fileManager);
  const deletedEntities = useAppSelector(
    (state) => state.workspace.deletedEntities,
  );
  const fileView = entityDisplayType ? entityDisplayType : displayType;

  const { contextMenu, handleContextMenu, closeContextMenu } = useContextMenu();
  useEffect(() => {
    if (
      selectedEntityIds.some((entityId) =>
        deletedEntities.some((deletion) => deletion.entityId === entityId),
      )
    ) {
      dispatch(setSelectedEntityIds([]));
    }
  }, [deletedEntities, selectedEntityIds, dispatch]);

  const handleSelectEntity = useCallback(
    (entityId: string, isShiftClick: boolean, isCtrlClick: boolean) => {
      if (isShiftClick && lastSelectedEntityId) {
        const startIndex = entities.findIndex(
          (entity) => entity.id === lastSelectedEntityId,
        );
        const endIndex = entities.findIndex((entity) => entity.id === entityId);

        const range = entities.slice(
          Math.min(startIndex, endIndex),
          Math.max(startIndex, endIndex) + 1,
        );
        const newSelectedIds = range.map((entity) => entity.id);

        dispatch(
          setSelectedEntityIds(
            Array.from(new Set([...selectedEntityIds, ...newSelectedIds])),
          ),
        );
      } else if (isCtrlClick) {
        if (selectedEntityIds.includes(entityId)) {
          dispatch(
            setSelectedEntityIds(
              selectedEntityIds.filter((id: string) => id !== entityId),
            ),
          );
        } else {
          dispatch(setSelectedEntityIds([...selectedEntityIds, entityId]));
        }
      }
      dispatch(setLastSelectedEntityId(entityId));
      const lastOpenedEntity = entities.find(
        (entity) => entity.id === entityId,
      );
      if (!lastOpenedEntity) return;
      dispatch(setLastOpenedEntity(lastOpenedEntity));
    },
    [entities, lastSelectedEntityId, selectedEntityIds, dispatch],
  );

  const { folders, files } = useMemo(
    () =>
      entities.reduce(
        (acc, item) => {
          if (
            item.type === 'folder' ||
            item.type === 'gallery' ||
            item.type === 'calendar'
          ) {
            acc.folders.push(item);
          } else if (item.type === 'file') {
            acc.files.push(item);
          }
          return acc;
        },
        { folders: [] as Entity[], files: [] as Entity[] },
      ),
    [entities],
  );

  const mainClass = clsx({
    'nk-files': true,
    [`nk-files-view-${fileView}`]: fileView,
  });

  const handleEntityRightClick = (
    entityId: string,
    position: { x: number; y: number },
  ) => {
    handleContextMenu({
      entityId,
      position,
    });
  };

  const handleCloseContextMenu = () => {
    closeContextMenu();
  };

  useEffect(() => {
    const handleGlobalClick = () => {
      if (contextMenu.display) {
        closeContextMenu();
      }
    };

    document.addEventListener('click', handleGlobalClick);
    return () => document.removeEventListener('click', handleGlobalClick);
  }, [contextMenu.display]);

  return (
    <div className={mainClass}>
      {entities.length > 0 && (
        <div className="nk-files-head">
          <div className="nk-file-item">
            {fileView === 'list' && (
              <>
                <div className="nk-file-info">
                  <div className="tb-head">
                    {t('FILE_MANAGER.FILES.FILENAME')}
                  </div>
                  <div className="tb-head"></div>
                </div>
                {!layoutType && (
                  <div className="nk-file-meta">
                    <div className="tb-head">
                      {t('FILE_MANAGER.FILES.LAST_MODIFIED')}
                    </div>
                  </div>
                )}
                {layoutType === 'trash' && (
                  <div className="nk-file-date">
                    <div className="tb-head">
                      {t('FILE_MANAGER.FILES.DELETED_ON')}
                    </div>
                  </div>
                )}
                <div className="nk-file-members">
                  <div className="tb-head">{t('FILE_MANAGER.FILES.OWNER')}</div>
                </div>
                <div className="nk-file-actions"></div>
              </>
            )}
          </div>
        </div>
      )}
      {(fileView === 'list' || fileView === 'grid') && (
        <div className="nk-files-list">
          {entities.map((item) => (
            <EntityComponent
              entityDisplayType={fileView}
              entity={item}
              key={item.id}
              isSelected={selectedEntityIds.includes(item.id)}
              onSelectEntity={handleSelectEntity}
              layoutType={layoutType}
              onRightClick={handleEntityRightClick}
              contextMenu={contextMenu}
              closeContextMenu={handleCloseContextMenu}
            />
          ))}
        </div>
      )}
      {fileView === 'group' && (
        <>
          <div className="nk-files-group">
            <h6 className="title border-top-0">
              {t('FILE_MANAGER.FILES.FOLDERS')}
            </h6>
            <div className="nk-files-list">
              {folders.map((item) => (
                <EntityComponent
                  entityDisplayType={fileView}
                  entity={item}
                  key={item.id}
                  isSelected={selectedEntityIds.includes(item.id)}
                  onSelectEntity={handleSelectEntity}
                  layoutType={layoutType}
                  onRightClick={handleEntityRightClick}
                  contextMenu={contextMenu}
                  closeContextMenu={handleCloseContextMenu}
                />
              ))}
            </div>
          </div>
          <div className="nk-files-group">
            <h6 className="title">{t('FILE_MANAGER.FILES.FILES')}</h6>
            <div className="nk-files-list">
              {files.map((item) => (
                <EntityComponent
                  entityDisplayType={fileView}
                  entity={item}
                  key={item.id}
                  isSelected={selectedEntityIds.includes(item.id)}
                  onSelectEntity={handleSelectEntity}
                  layoutType={layoutType}
                  onRightClick={handleEntityRightClick}
                  contextMenu={contextMenu}
                  closeContextMenu={handleCloseContextMenu}
                />
              ))}
            </div>
          </div>
        </>
      )}
      {entities.length === 0 && <div>{t('FILE_MANAGER.FILES.EMPTY')}</div>}
    </div>
  );
};
